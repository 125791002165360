import "react-toastify/dist/ReactToastify.css";
import "./Dashboard.css";
import React, {useState, useEffect} from "react";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import {useUser} from "../../DataContext";
import SocialMediaConnection from "../../../common/SocialMediaConnection";
import {apiClient} from "../../../config/axios";
import {createFormDataWithRepeatedKeys} from "../../../utils/CreateFormDataWithRepeatedKeys";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {useInviteToken} from "../../../context/useInviteToken";
import {Toaster} from "sonner";
import * as Frigade from '@frigade/react';
import {AnnouncementProps, Flow, Progress, Text} from "@frigade/react";
import {Button, Card, Dialog, Flex, studioTheme, ThemeProvider} from "@sanity/ui";
import GenerateReplySection from "../../../common/GenerateReplySection";
import DesktopDownloadSection from "../../../common/DesktopDownloadSection";
import BeeperComponent from "../../../common/BeeperComponent";
import DesktopDownloadInstructions from "../../../common/DesktopDownloadInstructions";
import RecantLogo from "../../../assets/images/RecantNewLogo.png";

const Dashboard = () => {
  const {user} = useUser();
  const navigate = useNavigate();
  const [connectedProfile, setConnectedProfile] = useState(null);
  const [error, setError] = useState(null);
  const formData = createFormDataWithRepeatedKeys();
  // const { invitedToken, inviterUserId, message, loading } = useInviteToken();
  const fetchUserDetails = async () => {
    try {
      const {data} = await apiClient.get("api/user");
      setConnectedProfile(data);
      localStorage.setItem("matrixUser", data?.matrix?.userId || null);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const uploadDankestPhoto = async () => {
      const dankestPhotos = localStorage.getItem("uploadedProfilePicture");

      if (dankestPhotos && connectedProfile?.id) {
        try {
          await apiClient.post(
            `/api/troll-register/dankest/${connectedProfile.id}`,
            formData
          );
          toast.success("image successfully uploaded");
          localStorage.removeItem("uploadedProfilePicture");
        } catch (err) {
          setError(err);
        }
      }
    };

    if (connectedProfile) {
      uploadDankestPhoto();
    }
  }, [connectedProfile]);

  const updateEnterpriseMode = async (isEnterprise) => {
    try {
      await apiClient.put(
        `/api/user/is-enterprise`,
        JSON.stringify({isEnterprise})
      );
      toast.success("All set!");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <OnBoardingLayout selected={1}>
      <Frigade.Provider apiKey={process.env.REACT_APP_FRIGADE_KEY} userId={user.id}>
        <Toaster richColors/>
        <Frigade.Announcement
          flowId="flow_qiDFSt8d"
          onSecondary={() => updateEnterpriseMode(true)}
        />
        <div className="bg-white w-full px-3 pt-3 h-screen mt-2">
          <button
            onClick={() => navigate("/")}
            className="flex items-center gap-2 hover:underline font-bold text-primaryColor hover:text-hoverBaseColor mb-4 ml-4"
          >
            <IoArrowBack size={20}/>
            <span>Back to Home</span>
          </button>
          <div className="flex flex-col items-center mt-6 lg:mt-24 md:w-8/12 w-full mx-auto ">

            <div className='mb-6'>
              <img src={RecantLogo} alt='Logo' className='h-12'/>
            </div>
            <h1 className="text-2xl text-grey text-center font-semibold mt-4 mb-2">
              Welcome, {user?.firstName}!
            </h1>
            <p className="text-lg text-grey -mt-1 text-center opacity-50">
              Let's get your social media accounts connected.
            </p>
          </div>
          {/*<DesktopDownloadInstructions/>*/}
          <SocialMediaConnection/>
        </div>
      </Frigade.Provider>
    </OnBoardingLayout>
  );
};

export default Dashboard;
