import React, {useState} from "react";
import {FaSquareXTwitter} from "react-icons/fa6";
import {FaPlus} from "react-icons/fa6";
import TwitterConsentModal from './TwitterConsentModal'; // Import the modal

const TwitterLoginButton = ({handleTwitterLogin}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);  // Opens the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);  // Closes the modal
  };

  return (
    <div>
      {/* Button to trigger modal */}
      <div onClick={openModal} className="cursor-pointer">
        <div
          className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3 w-full hover:border-[#bcbaba]">
          <div className="flex items-center justify-between w-full">
            <FaSquareXTwitter size={21} className=""/>
            {/*<FaPlus className="text-[#787575]" size={21} />*/}
          </div>
          <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2 pb-10">
            Connect Twitter
          </p>
        </div>
      </div>

      {/* Twitter Consent Modal */}
      {isModalOpen && (
        <TwitterConsentModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          handleTwitterLogin={handleTwitterLogin}
        />
      )}
    </div>
  );
};

export default TwitterLoginButton;
