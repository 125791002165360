import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {GrDownload} from "react-icons/gr";
import {FaApple, FaWindows} from "react-icons/fa";
import {FiCopy} from "react-icons/fi";
import RecantLogo from "../../assets/images/RecantNewLogo.png";
import {toast} from "sonner";
import SocialMediaConnection from "../SocialMediaConnection";

const BeeperComponent = ({handleCloseModal, showModal}) => {
  const [copied, setCopied] = useState(false);
  let matrixUser = localStorage.getItem("matrixUser");
  const extractedMatrixUser =
    matrixUser !== "null"
      ? matrixUser?.substring(1, matrixUser?.indexOf(":"))
      : null;
  const copyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value);
    toast.info("copied to clipboard");
    setCopied(true);
  };
  const downloadPackage = (build) => {

    const packageURL = `https://recant-desktop-packages.s3.ap-southeast-2.amazonaws.com/recant-desktop-${build}.zip`;

    const link = document.createElement("a");
    link.href = packageURL;
    link.download = "recant-desktop";
    link.click();
  };
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className='flex items-center justify-center z-50'
      backdrop='static'
      keyboard={false}
    >
      <div className='w-full flex flex-col items-center justify-center bg-gray-100 rounded-lg p-10 shadow-lg'>
        {/* Logo */}
        <div className='mb-6'>
          <img src={RecantLogo} alt='Logo' className='h-12'/>
        </div>

        {/* Download Icon */}
        <div className='mb-4 text-primaryColor'>
          <GrDownload fontSize={40}/>
        </div>

        {/* Heading */}
        <div className='text-center px-8 mb-10'>
          <p className='mt-2 font-normal text-grey'>
            Let's get everything set up for you.
          </p>
        </div>

        {/* Download Instructions */}
        <div className='text-md text-gray-700 text-left'>
          <ol className='space-y-10 list-decimal list-inside pl-0'>
            <li>
              Download Recant Desktop (Beta) for your platform:
              <div className='flex flex-col justify-center space-y-4 mt-3'>
                {/* Mac Download Button */}
                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1  hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("macos-amd64")}
                >
                  <FaApple className='text-primaryColor text-2xl'/>
                  <span>macOS (Apple Silicon)</span>
                </button>

                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1 hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("macos-universal")}
                >
                  <FaApple className='text-primaryColor text-2xl'/>
                  <span>macOS (Intel)</span>
                </button>
                {/* Windows Download Button */}
                <button
                  className='py-3 px-6  text-primaryColor rounded-lg font-medium shadow-sm border-1 hover:border-hoverPrimary  flex items-center space-x-3'
                  onClick={() => downloadPackage("windows")}
                >
                  <FaWindows className='text-primaryColor text-2xl'/>
                  <span>Windows</span>
                </button>
              </div>

              <div className="mt-6 text-grey">
                <p className="font-semibold">Not sure what kind of Mac you have?</p>
                <ol className="list-decimal pl-5">
                  <li>
                    Click the <strong>Apple logo ()</strong> in the top-left corner of your screen.
                  </li>
                  <li>
                    Select <strong>“About This Mac”</strong> from the dropdown menu.
                  </li>
                  <li>
                    Look at the first section of the window that appears:
                    <ul className="list-disc pl-10">
                      <li>
                        If you see <strong>“Chip”</strong> followed by a name like <strong>“M1,” “M2,”</strong> or
                        any <strong>“M”</strong> with a number after it, you have an <strong>Apple Silicon Mac</strong>.
                      </li>
                      <li>
                        If you see <strong>“Processor”</strong> followed by something like <strong>“Intel Core i5,” “Intel
                        Core i7,”</strong> etc., you have an <strong>Intel Mac</strong>.
                      </li>
                    </ul>
                  </li>
                </ol>
                <p className="mt-4 font-semibold">That’s it. Now you know which type of Mac you’re using!</p>
              </div>
            </li>


            <li>
              Sign into Recant Desktop with your username below and connect your messaging apps.
              {extractedMatrixUser && (
                <button
                  onClick={() => copyToClipboard(extractedMatrixUser)}
                  className={`flex text-start w-full items-center gap-2 bg-[#f5f5f5] p-3 rounded border mt-3 ${
                    copied
                      ? "border-hoverPrimary text-hoverPrimary"
                      : "border-gray-300 text-gray-500"
                  } hover:border-hoverPrimary`}
                  title='Copy Recant Username'
                >
                  <FiCopy className='text-lg'/>
                  <p className='text-md flex-1 flex-wrap truncate'>
                    {extractedMatrixUser}
                  </p>
                </button>
              )}
            </li>
          </ol>
        </div>

        {/* Footer Button */}
        <div className='mt-8 w-full flex justify-center px-12'>
          <button
            onClick={handleCloseModal}
            className='py-2 px-6  text-white rounded-lg font-bold shadow-lg  transition duration-300 bg-primaryColor w-full'
          >
            &larr; Back to Dashboard
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BeeperComponent;
